import React from "react";
import { Box, Typography } from "@mui/material";

const PhotoGallery: React.FC = () => (
    <Box sx={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h5">Galerie Photos</Typography>
        <Typography>Une galerie d'images sera ajoutée ici.</Typography>
    </Box>
);

export default PhotoGallery;