import React from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import TabNavigator from "./TabNavigator";

const App: React.FC = () => {
  return (
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            justifyContent: "space-between",
          }}
      >
        <Header />
        <Box
            component="main"
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f9f9f9",
            }}
        >
          <TabNavigator />
        </Box>
        <Footer />
      </Box>
  );
};

export default App;
