import React from "react";
import { Box, Typography } from "@mui/material";

const Calendar: React.FC = () => (
    <Box sx={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h5">Calendrier</Typography>
        <Typography>Un composant de calendrier sera ajouté ici.</Typography>
    </Box>
);

export default Calendar;