import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import Calendar from "./Calendar";
import PhotoGallery from "./PhotoGallerie";

const TabNavigator: React.FC = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                centered
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Calendrier" />
                <Tab label="Galerie Photos" />
            </Tabs>
            <Box sx={{ marginTop: "20px" }}>
                    {activeTab === 0 && <Calendar />}
                {activeTab === 1 && <PhotoGallery />}
            </Box>
        </Box>
    );
};

export default TabNavigator;
