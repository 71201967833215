import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

const Header: React.FC = () => (
    <AppBar position="static" sx={{ backgroundColor: "#007bff" }}>
        <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
                Mon Application React (MUI + TypeScript)
            </Typography>
        </Toolbar>
    </AppBar>
);

export default Header;